import Vue from "vue";
import Vuetify from "vuetify";
import es from '../translations/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#231f20",
        secondary: "#ab1d36",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  lang: {
    current: "es",
    locales : {
      es
    }
  },
  icons: {
    iconfont: 'mdiSvg'
  }
});
