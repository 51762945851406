import { DateTime } from "luxon";
import Vue from "vue";
//import moment from 'moment-timezone'

export function formatDateCompleted(value: string) {
  if(!value)  return 'Sin datos'
  return new Intl.DateTimeFormat("es-CL", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour12: true,
  }).format(new Date(value));
}

export function formatDate(value: string) {
  if(!value)  return 'Sin datos'
  return new Intl.DateTimeFormat("es-CL", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  }).format(new Date(value));
}

export function formatDateSimpleMS(value:number){
  if(!value)  return 'Sin datos'
  return DateTime.fromMillis(value).setZone("America/Santiago").toFormat("yyyy-MM-dd");
}

export function formatDateSimple(value: string) {
  if(!value)  return 'Sin datos'
  return DateTime.fromISO(value).setZone("America/Santiago").toFormat("yyyy-MM-dd");
}

Vue.filter('formatDateCompleted',formatDateCompleted)
Vue.filter('formatDate',formatDate)
Vue.filter('formatDateSimple',formatDateSimple)
Vue.filter('formatDateSimpleMS',formatDateSimpleMS)