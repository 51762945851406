import { STATUS_TRIP } from "../interfaces/trips";
import { MainModule } from "@/store/modules/Main";
import { ErrorResponse } from "./request";
import { AuthModule } from "@/store/modules/Auth";
import { IPermissionGroupProfile } from "@/interfaces/profile";
import { translations } from "@/translations/app";
import { STATUS_SCHEDULE_MAINTENANCE } from "@/types/scheduleMaintenance";
import {
 DetailPlanningStatus,
 StatusPlanning,
 StatusSchedule,
 TypeAssignSchedule,
} from "@/interfaces/planning/response";
import { DateTime } from "luxon";

/* eslint-disable */
export function getStatusTrip(status: STATUS_TRIP) {
 const statusTrip: Record<STATUS_TRIP, string> = {
  CANCELLED: "Cancelado",
  DELETED: "Eliminado",
  FINISHED: "Finalizado",
  PENDING: "Pendiente",
 };
 return statusTrip[status];
}

export interface StatusMainCommon {
 icon?: string;
 color?: string;
 name: string;
}

export const getRandomColor = () => {
 const colores = [
  "primary",
  "#DC143C",
  "#B22222",
  "#8B0000",
  "#C71585",
  "#800080",
  "#4B0082",
  "#0000FF",
  "#4169E1",
  "#4682B4",
  "#008000",
  "#228B22",
  "#006400",
  "#7B68EE",
  "#6A5ACD",
  "#483D8B",
  "#8B008B",
  "#9932CC",
  "#9400D3",
  "#8A2BE2",
  "#483D8B",
  "#4B0082",
  "#0000CD",
  "#000080",
  "#4169E1",
  "#4682B4",
  "#1E90FF",
  "#00BFFF",
  "#00CED1",
  "#20B2AA",
  "#008B8B",
  "#008080",
 ];
 const randonNumber = Math.floor(Math.random() * colores.length);
 return colores[randonNumber];
};

export function getTranslationStatusSchedule(
 statusSchedule: StatusSchedule,
): StatusMainCommon {
 const statusSchedules: Record<StatusSchedule, StatusMainCommon> = {
  ASSIGNED: {
   name: "Asignado",
   color: "green",
   icon: "mdi-check-circle",
  },
  EMPTY: {
   name: "No asignado",
   color: "red",
  },
  EXPIRED: {
   name: "Expirado",
   color: "red",
  },
  ON_HOLD: {
   name: "En espera",
   color: "blue",
   icon: "mdi-clock",
  },
  REASSIGNED: {
   name: "Reasignado",
   color: "orange",
   icon: "mdi-alert",
  },
  DELETED : {
    name: 'Eliminado',
    color: 'red',
    icon : 'mdi-delete-alert'
  }
 };
 return statusSchedules[statusSchedule] || "";
}

export function getTranslationTypeAssignSchedule(
 typeAssing: TypeAssignSchedule,
): StatusMainCommon {
 const typesAssing: Record<TypeAssignSchedule, StatusMainCommon> = {
  AUTO: {
   name: "Automático",
   color: "blue",
  },
  MANUAL: {
   name: "Manual",
   color: "blue",
  },
  MANUAL_CREATION: {
   name: "Manual por creación",
   color: "blue",
  },
 };
 return typesAssing[typeAssing] || "";
}

export function getTranslationStatusPlanningDetail(
 status: DetailPlanningStatus,
): StatusMainCommon {
 const statusDetailPlanning: Record<DetailPlanningStatus, StatusMainCommon> = {
  ON_HOLD: {
   name: "En espera",
   icon: "mdi-clock-outline",
   color: "orange",
  },
  IN_PROGRESS: {
   name: "En progreso",
   icon: "mdi-car",
   color: "green",
  },
  TRANSFER: {
   name: "Transferido",
   color: "orange",
   icon: "mdi-briefcase-arrow-left-right",
  },
  FINALIZED: {
   name: "Finalizado",
   color: "secondary",
   icon: "mdi-check-circle",
  },
  DELETED : {
    name: 'Eliminado',
    color: 'red',
    icon : 'mdi-delete-alert'
  }
 };
 return statusDetailPlanning[status] || { name: "" };
}

export function getTranslationStatusPlanning(status: StatusPlanning): StatusMainCommon {
 const statusPlanning: Record<StatusPlanning, StatusMainCommon> = {
  DRAFT: {
   name: "Borrador",
   icon: "mdi-eraser",
   color: "grey",
  },
  ON_HOLD: {
   name: "En espera",
   icon: "mdi-clock-outline",
   color: "orange",
  },
  IN_PROGRESS: {
   name: "En progreso",
   icon: "mdi-car",
   color: "green",
  },
  FINALIZED: {
   name: "Finalizado",
   color: "secondary",
   icon: "mdi-check-circle",
  },
  CANCELED: {
   name: "Cancelado",
   color: "red",
   icon: "mdi-block-helper",
  },
  DELETED: {
   name: "Eliminado",
   color: "red",
   icon: "mdi-delete",
  },
 };
 return statusPlanning[status] || { name: "" };
}

interface IStatusValue {
 color: string;
 value: string;
}

export const VALUES_STATUS_TRIP: Record<STATUS_TRIP, IStatusValue> = {
 CANCELLED: {
  color: "red",
  value: "Cancelado",
 },
 DELETED: {
  color: "red",
  value: "Eliminado",
 },
 FINISHED: {
  value: "Finalizado",
  color: "green darken-3",
 },
 PENDING: {
  value: "Pendiente",
  color: "orange darken-2",
 },
};

export const getErrorCatch = (error: ErrorResponse | any, sendMessage = true) => {
 let message = error.message || "Ocurrio un error";
 let errorCode = 401;
 if (error instanceof ErrorResponse) {
  message = error.message;
  errorCode = error.statusCode;
 }
 if (sendMessage) {
  MainModule.setNotification({
   isOpen: true,
   message,
   color: "red darken-4",
  });
 }
 return {
  message,
  errorCode,
 };
};

export function fixedKm(value?: number) {
 if (value) {
  const divider = Math.pow(10, 2);
  return Math.round((value + Number.EPSILON) * divider) / divider;
 }
}

export function validateNumber(evt: KeyboardEvent) {
 if (
  evt.key !== "." &&
  evt.key !== "Backspace" &&
  evt.key !== "Tab" &&
  evt.key !== "Escape"
 ) {
  const parsetKey = parseFloat(evt.key);
  if (isNaN(parsetKey)) evt.preventDefault();
 }
}

export function setPermissions(pageName: string) {
 if (AuthModule.currentUser && AuthModule.currentUser.profile) {
  const permissions = AuthModule.currentUser.profile
   .permissions as IPermissionGroupProfile[];
  const tripsPermission = permissions.find(({ page }) => page === pageName);
  if (tripsPermission) {
   return tripsPermission.actions;
  }
 }
 return null;
}

export function getStatusSchedule(status: STATUS_SCHEDULE_MAINTENANCE) {
 return translations.statusMaintenance[status] || "";
}

export function setDateNoon(date: Date) {
 return DateTime.fromJSDate(date)
  .setZone(MainModule.timeZone)
  .set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
}

export function getDatesRange(fechaInicio: Date, fechaFin: Date): Date[] {
 if (fechaInicio > fechaFin) {
  throw new Error("La fecha de inicio debe ser anterior o igual a la fecha de fin.");
 }
 const fechas: Date[] = [];
 let fechaActual = new Date(fechaInicio);

 while (fechaActual <= fechaFin) {
  fechas.push(setDateNoon(fechaActual).toJSDate());
  fechaActual.setDate(fechaActual.getDate() + 1);
 }

 return fechas;
}
