import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import ErrorPage from "../views/ErrorPage.vue";
import Layout from "../layout/LayoutMain.vue";
import onlySuper from "../middleware/onlySuper";

Vue.use(VueRouter);

/* eslint-disable */

const routes: Array<RouteConfig> = [
 {
  path: "*",
  redirect: "/404",
  meta: { hidden: true },
 },
 {
  path: "/404",
  component: ErrorPage,
  meta: { hidden: true },
 },
 {
  path: "/login",
  name: "login",
  component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
 },
 {
  path: "/dashboard",
  component: Layout,
  children: [
   {
    path: "/dashboard",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
   },
   {
    path: "/plannings",
    name: "plannings",
    component: () => import(/* webpackChunkName: "plannings" */ "../views/trips/index.vue"),
   },
   {
    path: "/plannings/create",
    name: "plannings-create",
    component: () =>
     import(/* webpackChunkName: "createTrip" */ "../views/trips/create.vue"),
   },
   {
    path: "/plannings/:id",
    name: "planning-detail",
    component: () =>
     import(/* webpackChunkName: "detailPlanning" */ "../views/trips/detail.vue"),
   },
   {
    path: "/routes",
    name: "routes",
    component: () => import(/* webpackChunkName: "routes" */ "../views/routes/index.vue"),
   },
   {
    path: "/routes/regions",
    name: "regions",
    component: () =>
     import(/* webpackChunkName: "regions" */ "../views/routes/regions/index.vue"),
   },
   {
    path: "/drivers",
    name: "drivers",
    component: () =>
     import(/* webpackChunkName: "regions" */ "../views/drivers/index.vue"),
   },
   {
    path: "/company",
    name: "company",
    component: () =>
     import(/* webpackChunkName: "regions" */ "../views/company/index.vue"),
   },
   {
    path: "/customers",
    name: "customers",
    component: () =>
     import(/* webpackChunkName: "regions" */ "../views/customers/index.vue"),
   },
   {
    path: "/providers",
    name: "providers",
    component: () =>
     import(/* webpackChunkName: "regions" */ "../views/providers/index.vue"),
   },
   {
    path: "/vehicles",
    name: "vehicles",
    component: () =>
     import(/* webpackChunkName: "regions" */ "../views/vehicles/index.vue"),
   },
   {
    path: "/vehicle/:id",
    name: "vehicles-detail",
    component: () =>
     import(/* webpackChunkName: "detail vehicles" */ "../views/vehicles/detailVehicle.vue"),
   },
   {
    path: "/bills",
    name: "bills",
    component: () => import(/* webpackChunkName: "regions" */ "../views/bills/index.vue"),
   },
   {
    path: "/bills/vehicles",
    name: "vehicleBillsPage",
    component: () => import(/* webpackChunkName: "regions" */ "../views/bills/vehicles.vue"),
   },
   {
    path: "/income",
    name: "income",
    component: () => import(/* webpackChunkName: "regions" */ "../views/income/index.vue"),
   },
   {
    path: "/vehicle-parts",
    name: "vehiclesParts",
    component: () => import(/* webpackChunkName: "regions" */ "../views/parts/index.vue"),
   },
   {
    path: "/maintenance-schedule",
    name: "maintenanceSchedule",
    component: () =>
     import(/* webpackChunkName: "regions" */ "../views/schedule-maintenance/index.vue"),
   },
   {
    name: "brands",
    path: "/brands",
    component: () =>
     import(/* webpackChunkName: "regions" */ "../views/brands/index.vue"),
   },
   {
    path: "/settlements",
    name: "settlements",
    component: () =>
     import(/* webpackChunkName: "regions" */ "../views/settlements/index.vue"),
   },
   {
    path: "/billing",
    name: "billing",
    component: () =>
     import(/* webpackChunkName: "regions" */ "../views/billing/index.vue"),
   },
   {
    path: "/admins",
    name: "admins",
    component: () =>
     import(/* webpackChunkName: "regions" */ "../views/admins/index.vue"),
    beforeEnter: (to, from, next) => {
     onlySuper(to, from, next);
    },
   },
   {
    path: "/profiles",
    name: "profiles",
    component: () =>
     import(/* webpackChunkName: "regions" */ "../views/profiles/index.vue"),
    beforeEnter: (to, from, next) => {
     onlySuper(to, from, next);
    },
   },
  ],
 },
 {
  path: "/",
  redirect: "/login",
 },
];

const createRouter = () =>
 new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
   if (savedPosition) {
    return savedPosition;
   }
   return { x: 0, y: 0 };
  },
 });

const router = createRouter();

export function resetRouter() {
 const newRouter = createRouter();
 (router as any).matcher = (newRouter as any).matcher;
}

export default router;
